import { Tag } from "antd";
import React from "react";

const StatusTag = ({ status }) => {
  switch (status) {
    case 0:
      return (
        <Tag className="tags" color="#c73b27">
          Inactive
        </Tag>
      );
    case 1:
      return (
        <Tag className="tags" color="#0d8c63">
          Active
        </Tag>
      );
    case 2:
      return (
        <Tag className="tags" color="#F29339">
          Pending
        </Tag>
      );
    case 7:
      return (
        <Tag className="tags" color="#077E8C">
          Published
        </Tag>
      );
    case 8:
      return (
        <Tag className="tags" color="#D9512C">
          Unpublished
        </Tag>
      );
    case 9:
      return (
        <Tag className="tags" color="#800000">
          Deleted
        </Tag>
      );
    case -9:
      return (
        <Tag className="tags" color="#800000">
          Deleted
        </Tag>
      );
    case -1:
      return (
        <Tag className="tags" color="#800000">
          Deleted
        </Tag>
      );
    default:
      return (
        <Tag className="tags" color="#0000FF">
          Unknown
        </Tag>
      );
  }
};

export default StatusTag;
