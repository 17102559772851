export const IMAGE_PATH = process.env.REACT_APP_IMAGEKIT_URL;
export const BASE_API_URL = process.env.REACT_APP_API_URL;

export const apis = {
  ADMIN_LOGIN: `${BASE_API_URL}api/v1/admin/login`,
  LOG_DATA: `${BASE_API_URL}api/v1/log-data`,
  CHECK_IP: `${BASE_API_URL}api/v1/check-ip`,
  FORGOT_PASSWORD: `${BASE_API_URL}api/v1/admin/forgot-password`,
  RESET_PASSWORD: `${BASE_API_URL}api/v1/admin/reset-password`,

  COUNTRY_LIST: `${BASE_API_URL}api/v1/countries`,
  STATE_LIST: `${BASE_API_URL}api/v1/states`,
  CITY_LIST: `${BASE_API_URL}api/v1/cities`,
  FILE_UPLOAD: `${BASE_API_URL}api/file-upload`,
  FILE_EXPORT: `${BASE_API_URL}api/v1/admin/export`,
  FILE_DOWNLOAD: `${BASE_API_URL}api/v1/admin/download`,
  FILE_IMPORT: `${BASE_API_URL}api/v1/admin/import-csv`,
  FILE_IMPORT_JSON: `${BASE_API_URL}api/v1/admin/import-json`,

  COURSE_LEVELS: `${BASE_API_URL}api/v1/admin/course-levels`,
  COURSE_LEVEL_CREATE: `${BASE_API_URL}api/v1/admin/course-levels/create`,
  COURSE_LEVEL_BY_ID: `${BASE_API_URL}api/v1/admin/course-levels`,
  COURSE_LEVEL_UPDATE: `${BASE_API_URL}api/v1/admin/course-levels/update`,
  COURSE_LEVEL_DELETE: `${BASE_API_URL}api/v1/admin/course-levels/delete`,

  REQUIRED_DOCUMENTS: `${BASE_API_URL}api/v1/admin/required-documents`,
  REQUIRED_DOCUMENTS_CREATE: `${BASE_API_URL}api/v1/admin/required-documents/create`,
  REQUIRED_DOCUMENTS_BY_ID: `${BASE_API_URL}api/v1/admin/required-documents`,
  REQUIRED_DOCUMENTS_UPDATE: `${BASE_API_URL}api/v1/admin/required-documents/update`,
  REQUIRED_DOCUMENTS_DELETE: `${BASE_API_URL}api/v1/admin/required-documents/delete`,

  INTAKE_MONTHS: `${BASE_API_URL}api/v1/admin/intake-months`,
  INTAKE_MONTHS_CREATE: `${BASE_API_URL}api/v1/admin/intake-months/create`,
  INTAKE_MONTHS_BY_ID: `${BASE_API_URL}api/v1/admin/intake-months`,
  INTAKE_MONTHS_UPDATE: `${BASE_API_URL}api/v1/admin/intake-months/update`,
  INTAKE_MONTHS_DELETE: `${BASE_API_URL}api/v1/admin/intake-months/delete`,

  INTAKE_YEARS: `${BASE_API_URL}api/v1/admin/intake-years`,
  INTAKE_YEARS_CREATE: `${BASE_API_URL}api/v1/admin/intake-years/create`,
  INTAKE_YEARS_BY_ID: `${BASE_API_URL}api/v1/admin/intake-years`,
  INTAKE_YEARS_UPDATE: `${BASE_API_URL}api/v1/admin/intake-years/update`,
  INTAKE_YEARS_DELETE: `${BASE_API_URL}api/v1/admin/intake-years/delete`,

  SUBJECTS: `${BASE_API_URL}api/v1/subjects`,
  SUBJECTS_CREATE: `${BASE_API_URL}api/v1/admin/subjects/create`,
  SUBJECTS_BY_ID: `${BASE_API_URL}api/v1/admin/subjects`,
  SUBJECTS_UPDATE: `${BASE_API_URL}api/v1/admin/subjects/update`,
  SUBJECTS_DELETE: `${BASE_API_URL}api/v1/admin/subjects/delete`,

  // User Management
  USER_LIST: `${BASE_API_URL}api/v1/admin/user-list`,
  USER_LIST_AGENT: `${BASE_API_URL}api/v1/admin/user-list-agent`,
  CREATE_USER: `${BASE_API_URL}api/v1/admin/create-user`,
  USER_INVITATION_SEND: `${BASE_API_URL}api/v1/admin/user/send-invitation`,
  RESEND_INVITATION: `${BASE_API_URL}api/v1/admin/user/re-send-invitation`,
  INVITATION_LIST: `${BASE_API_URL}api/v1/admin/user/invitations-list`,
  USER_TYPES: `${BASE_API_URL}api/v1/admin/user-types`,
  WELCOME_USER: `${BASE_API_URL}api/v1/admin/welcome-user`,
  USER_REGISTER: `${BASE_API_URL}api/v1/admin/register`,
  USER_CHANGE: `${BASE_API_URL}api/v1/admin/user/change-status`,
  USER_BY_ID: `${BASE_API_URL}api/v1/admin/user-by-id`,
  PROFILE_UPDATE: `${BASE_API_URL}api/v1/admin/user/edit-profile`,
  CHANGE_PASSWORD: `${BASE_API_URL}api/v1/admin/user/change-password`,
  ADD_USER_TYPE: `${BASE_API_URL}api/v1/admin/add-user-type`,
  DELETE_USER_TYPE: `${BASE_API_URL}api/v1/admin/delete-user-type`,

  UNIVERSITY_LIST: `${BASE_API_URL}api/v1/admin/university/get-list`,
  CREATE_UNIVERSITY: `${BASE_API_URL}api/v1/admin/university/create`,
  CHANGE_UNIVERSITY_STATUS: `${BASE_API_URL}api/v1/admin/university/change-status`,
  UNIVERSITY_BY_ID: `${BASE_API_URL}api/v1/admin/university`,
  UNIVERSITY_BY_COUNTRY: `${BASE_API_URL}api/v1/admin/university/filter-by-country`,
  UPDATE_UNIVERSITY: `${BASE_API_URL}api/v1/admin/university/update`,
  DELETE_UNIVERSITY: `${BASE_API_URL}api/v1/admin/university/delete`,
  UNIVERSITY_ORDER_UPDATE: `${BASE_API_URL}api/v1/admin/university/update-order`,

  COURSES_LIST: `${BASE_API_URL}api/v1/admin/course/get-list`,
  CREATE_COURSES: `${BASE_API_URL}api/v1/admin/course/create`,
  COURSES_BY_ID: `${BASE_API_URL}api/v1/admin/course`,
  DELETE_COURSE: `${BASE_API_URL}api/v1/admin/course/delete`,
  UPDATE_COURSE: `${BASE_API_URL}api/v1/admin/course/update`,
  COURSE_INTAKE_LIST: `${BASE_API_URL}api/v1/admin/course/course-intake-list`,

  CREATE_ENQUIRIES: `${BASE_API_URL}api/v1/admin/enquiries/create`,
  ENQUIRY_LIST: `${BASE_API_URL}api/v1/admin/enquiries/enquiries-list`,
  ENQUIRY_BY_ID: `${BASE_API_URL}api/v1/admin/enquiries`,
  UPDATE_ENQUIRIES: `${BASE_API_URL}api/v1/admin/enquiries/update`,
  DELETE_ENQUIRY: `${BASE_API_URL}api/v1/admin/enquiries/delete`,
  REJECT_ENQUIRY: `${BASE_API_URL}api/v1/admin/enquiries/reject`,
  ASSIGN_EMPLOYEE: `${BASE_API_URL}api/v1/admin/enquiries/assign-emp`,
  CREATE_ENQUIRIES_REF: `${BASE_API_URL}api/v1/admin/referral/enquiries/create`,

  APPLICATION_LIST: `${BASE_API_URL}api/v1/admin/application/application-list`,
  DELETE_APPLICATION: `${BASE_API_URL}api/v1/admin/application/delete`,
  HISTORY_APPLICATION: `${BASE_API_URL}api/v1/admin/application/history`,
  ADD_REMARKS: `${BASE_API_URL}api/v1/admin/application/add-remarks`,
  REMARK_LIST: `${BASE_API_URL}api/v1/admin/application/remarks-list`,
  APPLICATION_BY_ID: `${BASE_API_URL}api/v1/admin/application`,

  APPLICATION_STATUS_LIST: `${BASE_API_URL}api/v1/admin/application-status/list`,
  CREATE_APPLICATION_STATUS: `${BASE_API_URL}api/v1/admin/application-status/create`,
  UPDATE_APPLICATION_STATUS: `${BASE_API_URL}api/v1/admin/application-status/update`,
  DELETE_APPLICATION_STATUS: `${BASE_API_URL}api/v1/admin/application-status/delete`,
  APPLICATION_STATUS_BY_ID: `${BASE_API_URL}api/v1/admin/application-status`,

  SCHOLARSHIP_LIST: `${BASE_API_URL}api/v1/admin/scholarship/list`,
  CREATE_SCHOLARSHIP: `${BASE_API_URL}api/v1/admin/scholarship/create`,
  UPDATE_SCHOLARSHIP: `${BASE_API_URL}api/v1/admin/scholarship/update`,
  DELETE_SCHOLARSHIP: `${BASE_API_URL}api/v1/admin/scholarship/delete`,
  SCHOLARSHIP_BY_ID: `${BASE_API_URL}api/v1/admin/scholarship`,
  SCHOLARSHIP_TYPES: `${BASE_API_URL}api/v1/admin/scholarship-types`,

  WORKFLOW_LIST: `${BASE_API_URL}api/v1/admin/workflows/list`,
  CREATE_WORKFLOW: `${BASE_API_URL}api/v1/admin/workflows/create`,
  UPDATE_WORKFLOW: `${BASE_API_URL}api/v1/admin/workflows/update`,
  DELETE_WORKFLOW: `${BASE_API_URL}api/v1/admin/workflows/delete`,
  WORKFLOW_BY_ID: `${BASE_API_URL}api/v1/admin/workflows`,

  DASHBOARD: `${BASE_API_URL}api/v1/admin/dashboard`,
  CHART: `${BASE_API_URL}api/v1/admin/dashboard-chart`,
  PIE_CHART_APP: `${BASE_API_URL}api/v1/admin/dashboard-pie-chart-app`,
  COUNTRY_CHANGE_STATUS: `${BASE_API_URL}api/v1/admin/countries/change-status`,

  PAGE_PRIVILEGE: `${BASE_API_URL}api/v1/admin/page-privilege`,
  PAGE_PRIVILEGE_UPDATE: `${BASE_API_URL}api/v1/admin/page-privilege-update`,

  ACTION_PRIVILEGE: `${BASE_API_URL}api/v1/admin/action-privilege`,
  ACTION_PRIVILEGE_UPDATE: `${BASE_API_URL}api/v1/admin/action-privilege-update`,

  USER_REPORTS: `${BASE_API_URL}api/v2/admin/generate-report`,
  DELETE_RECORD: `${BASE_API_URL}api/v2/admin/delete`,

  CKEDITOR_UPLOAD: `${BASE_API_URL}api/v2/admin/ckeditor-upload`,

  EVENT_CREATE: `${BASE_API_URL}api/v2/admin/event/create`,
  EVENT_LIST: `${BASE_API_URL}api/v2/admin/event/list`,
  EVENT_STATUS_CHANGE: `${BASE_API_URL}api/v2/admin/event/status-change`,
  EVENT_DATA: `${BASE_API_URL}api/v2/admin/event`,
  EVENT_UPDATE: `${BASE_API_URL}api/v2/admin/event/update`,
  EVENT_DELETE: `${BASE_API_URL}api/v2/admin/event/Parament-delete`,
  SUB_EVENT_CREATE: `${BASE_API_URL}api/v2/admin/sub-event/create`,

  POPUP_MSG: `${BASE_API_URL}api/v2/admin/popup-message`,
  POPUP_MSG_UPDATE: `${BASE_API_URL}api/v2/admin/popup-message/update`,

  CAREER_CREATE: `${BASE_API_URL}api/v2/admin/career/create`,
  CAREER_LIST: `${BASE_API_URL}api/v2/admin/careers`,
  CAREER_STATUS_CHANGE: `${BASE_API_URL}api/v2/admin/career/status-change`,
  CAREER_DATA: `${BASE_API_URL}api/v2/admin/career`,
  CAREER_UPDATE: `${BASE_API_URL}api/v2/admin/career/update`,

  BLOGS: `${BASE_API_URL}api/v1/admin/blog`,

  SYNC_SCRAP_DATA: `${BASE_API_URL}api/v1/admin/sync-scrap-data`,
  SF_STATUS: `${BASE_API_URL}api/v1/sf-status`,
  SF_STATUS_UPDATE: `${BASE_API_URL}api/v1/sf-data-update`,
  SF_STATUS_BY_ID: `${BASE_API_URL}api/v1/sf-data`,
};
