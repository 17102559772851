import "react-progress-2/main.css";
import "./assets/css/Dashboard.css";
import "./assets/css/Header.css";
import "./assets/css/Login.css";
import "./assets/css/MenuSideBar.css";
import "./assets/css/Responsive.css";
import "./assets/css/ThemeChange.css";

import Progress from "react-progress-2";
import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import ApplicationHistoryTable from "./components/Application/ApplicationHistoryTable";
import AddBlog from "./components/Blogs/AddBlog";
import EditBlog from "./components/Blogs/EditBlog";
import AddCareer from "./components/Careers/AddCareer";
import EditCareer from "./components/Careers/EditCareer";
import AddCourses from "./components/Courses/AddCourses";
import EditCourses from "./components/Courses/EditCourses";
import AddEnquiry from "./components/Enquiry/AddEnquiry";
import EditEnquiry from "./components/Enquiry/EditEnquiry";
import ViewApplication from "./components/Enquiry/ViewApplication";
import ViewEnquiry from "./components/Enquiry/ViewEnquiry";
import AddEvent from "./components/Event/AddEvent";
import EditEvent from "./components/Event/EditEvent";
import SubEvents from "./components/Event/SubEvents";
import AddScholarship from "./components/Scholarship/AddScholarship";
import EditScholarship from "./components/Scholarship/EditScholarship";
import ActionPrivilege from "./components/Settings/ActionPrivilege/ActionPrivilege";
import ApplicationStatus from "./components/Settings/ApplicationStatus/ApplicationStatus";
import CorsesLevel from "./components/Settings/CorsesLevel/CorsesLevel";
import CountryManagement from "./components/Settings/CountryManagement/CountryManagement";
import IntakeMonths from "./components/Settings/IntakeMonths/IntakeMonths";
import IntakeYears from "./components/Settings/IntakeYears/IntakeYears";
import MyProfile from "./components/Settings/MyProfile";
import PagePrivilege from "./components/Settings/PagePrivilege/UserPrivilege";
import RequirementsDocuments from "./components/Settings/RequirementsDocuments/RequirementsDocuments";
import SubjectManage from "./components/Settings/Subjects/SubjectManage";
import ScrapDataSync from "./components/Settings/Sync/ScrapDataSync";
import UserType from "./components/Settings/UserType";
import AddUniversity from "./components/UniversityManagement/AddUniversity";
import EditUniversity from "./components/UniversityManagement/EditUniversity";
import AddUser from "./components/UserManagement/AddUser";
import InviteUsers from "./components/UserManagement/InviteUsers";
import AddWorkflows from "./components/Workflows/AddWorkflows";
import EditWorkflows from "./components/Workflows/EditWorkflows";
import NotFount from "./errorPage/404";
import ApplicationsManagement from "./pages/ApplicationsManagement";
import Blogs from "./pages/BlogsManagement";
import Careers from "./pages/Careers";
import CoursesManagement from "./pages/CoursesManagement";
import Dashboard from "./pages/Dashboard";
import Enquiries from "./pages/Enquiries";
import Events from "./pages/Events";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import PopUpManagement from "./pages/PopUpManagement";
import ReferralPage from "./pages/ReferralPage";
import Reports from "./pages/Reports";
import ResetPassword from "./pages/ResetPassword";
import Scholarship from "./pages/Scholarship";
import Settings from "./pages/Settings";
import UniversityManagement from "./pages/UniversityManagement";
import UserManagement from "./pages/UserManagement";
import WelcomeSuccess from "./pages/WelcomeSuccess";
import WelcomeUser from "./pages/WelcomeUser";
import Workflows from "./pages/Workflows";
import CourseListView from "./components/Settings/Sync/CourseListView";
import SalesforcePage from "./components/Application/SalesforcePage";

function App() {
  return (
    <div className="App">
      <Progress.Component />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route path="/user-invitation/:id" element={<WelcomeUser />} />
        <Route path="/user-register-success" element={<WelcomeSuccess />} />

        <Route path="/referral/:id" element={<ReferralPage />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />

          {/* === User Management === */}
          <Route path="/user-management" element={<UserManagement />} />
          <Route
            path="/user-management/invite-user"
            element={<InviteUsers />}
          />
          <Route path="/user-management/create-user" element={<AddUser />} />

          {/* === University Management === */}
          <Route
            path="/university-management"
            element={<UniversityManagement />}
          />
          <Route
            path="/university-management/create"
            element={<AddUniversity />}
          />
          <Route
            path="/university-management/edit/:id"
            element={<EditUniversity />}
          />

          {/* === Courses Management === */}
          <Route path="/courses-management" element={<CoursesManagement />} />

          <Route path="/courses-management/create" element={<AddCourses />} />

          <Route
            path="/courses-management/edit/:id"
            element={<EditCourses />}
          />

          {/* === Applications Management === */}
          <Route
            path="/applications-management"
            element={<ApplicationsManagement />}
          />
          <Route
            path="/applications-management/:id"
            element={<ViewApplication />}
          />
          <Route
            path="/applications-management/history/:id"
            element={<ApplicationHistoryTable />}
          />

          <Route
            path="/applications-management/salesforce/:id"
            element={<SalesforcePage />}
          />

          {/* === Enquiries === */}
          <Route path="/enquiries" element={<Enquiries />} />
          <Route path="/enquiries/create" element={<AddEnquiry />} />
          <Route path="/enquiries/edit/:id" element={<EditEnquiry />} />
          <Route path="/enquiries/:id" element={<ViewEnquiry />} />

          {/* === Workflows === */}
          <Route path="/workflows" element={<Workflows />} />
          <Route path="/workflows/create" element={<AddWorkflows />} />
          <Route path="/workflows/edit/:id" element={<EditWorkflows />} />

          {/* === Scholarship === */}
          <Route path="/scholarship" element={<Scholarship />} />
          <Route path="/scholarship/create" element={<AddScholarship />} />
          <Route path="/scholarship/edit/:id" element={<EditScholarship />} />

          {/* === Reports === */}
          <Route path="/reports" element={<Reports />} />

          {/* === Events === */}
          <Route path="/event-management" element={<Events />} />
          <Route path="/event-management/create" element={<AddEvent />} />
          <Route path="/event-management/edit/:slug" element={<EditEvent />} />
          <Route path="/event-management/sub/:slug" element={<SubEvents />} />

          <Route path="/popup-message" element={<PopUpManagement />} />

          {/* === Career Management === */}

          <Route path="/career-management" element={<Careers />} />
          <Route path="/career-management/create" element={<AddCareer />} />
          <Route
            path="/career-management/edit/:slug"
            element={<EditCareer />}
          />

          {/* === Blog Management === */}

          <Route path="/blog-management" element={<Blogs />} />
          <Route path="/blog-management/create" element={<AddBlog />} />
          <Route path="/blog-management/edit/:slug" element={<EditBlog />} />

          {/* === Settings === */}
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/user-type" element={<UserType />} />
          <Route path="/settings/page-privilege" element={<PagePrivilege />} />
          <Route
            path="/settings/action-privilege"
            element={<ActionPrivilege />}
          />
          <Route path="/settings/my-profile" element={<MyProfile />} />
          <Route path="/settings/corses-level" element={<CorsesLevel />} />
          <Route path="/settings/subjects" element={<SubjectManage />} />
          <Route
            path="/settings/requirements-documents"
            element={<RequirementsDocuments />}
          />
          <Route path="/settings/intake-months" element={<IntakeMonths />} />
          <Route path="/settings/intake-years" element={<IntakeYears />} />
          <Route
            path="/settings/application-status"
            element={<ApplicationStatus />}
          />
          <Route
            path="/settings/country-management"
            element={<CountryManagement />}
          />
          {/* <Route path="/settings/devices-list" element={<DevicesList />} /> */}
          <Route
            path="/settings/scraping-data-sync"
            element={<ScrapDataSync />}
          />
          <Route
            path="/settings/scraping-data-sync/:pageKey/:id"
            element={<CourseListView />}
          />
        </Route>

        <Route path="*" element={<NotFount />} />
      </Routes>
    </div>
  );
}

export default App;

// SELECT `application`.*,  `enquiries`.*, `courses`.`course_name`, `university`.`uni_name`, `u1`.`fname` as 'added_ufname' ,`u1`.`email` as 'added_uemail', `u1`.`role_id` as 'added_urole',  `u2`.`fname` as 'asign_ufname' ,`u2`.`email` as 'asign_uemail', `u2`.`role_id` as 'asign_urole'  FROM `application`
// RIGHT JOIN `enquiries`
// ON `enquiries`.id = `application`.enq_id
// RIGHT JOIN `courses`
// ON `courses`.`course_id` = `application`.`course_id`
// RIGHT JOIN `university`
// ON `university`.`uni_id` = `application`.`university_id`
// LEFT JOIN `users` as `u1`
// ON `u1`.`u_id` = `application`.`added_by`
// LEFT JOIN `users` as `u2`
// ON `u2`.`u_id` = `application`.`assign_emp`

// SELECT `application`.*, `enquiries`.*, `courses`.`course_name`, `university`.`uni_name`, `u1`.`fname` as 'added_ufname' ,`u1`.`email` as 'added_uemail', `u1`.`role_id` as 'added_urole', `u2`.`fname` as 'asign_ufname' ,`u2`.`email` as 'asign_uemail', `u2`.`role_id` as 'asign_urole' , `application_remarks`.* FROM `application` RIGHT JOIN `enquiries` ON `enquiries`.id = `application`.enq_id RIGHT JOIN `courses` ON `courses`.`course_id` = `application`.`course_id` RIGHT JOIN `university` ON `university`.`uni_id` = `application`.`university_id` LEFT JOIN `users` as `u1` ON `u1`.`u_id` = `application`.`added_by` LEFT JOIN `users` as `u2` ON `u2`.`u_id` = `application`.`assign_emp` LEFT JOIN `application_remarks` ON `application_remarks`.`rem_app_id` = `application`.`app_id`
