import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";

const { Option } = Select;

export default function ScrapDataSync() {
  const location = useLocation();
  useBreadCrumb("Scrap Data Sync", location.pathname, "", "add");
  const [scrapData, setScrapData] = useState([]);
  const [pageKey, setPageKey] = useState("UK");

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/get-scarp-json?country=${pageKey}`
      );
      const data = await response.json();
      setScrapData(data.data);
    };

    fetchData();
  }, [pageKey]);

  // const handleSync = async (id) => {};

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          Sync the scrap data | <span>Scrap Data Sync</span>
        </p>
        <div className="select_op">
          <label>Select Country</label>
          <Select
            placeholder="Select page key"
            allowClear
            size="large"
            style={{ width: 250 }}
            onChange={(value) => {
              setPageKey(value);
            }}
            value={pageKey}
          >
            <Option value={"AU"}>Australia</Option>
            <Option value={"CA"}>Canada</Option>
            <Option value={"NZ"}>New Zealand</Option>
            <Option value={"UK"}>United Kingdom</Option>
          </Select>
        </div>
        {scrapData.map((data) => (
          <div
            className="data_row"
            key={data.id}
            style={{
              marginBottom: 10,
            }}
          >
            <div>
              <Link to={`/settings/scraping-data-sync/${pageKey}/${data.id}`}>
                {data.name}
              </Link>
              {/* {data.isSynced ? (
                <Tag color="green">Synced</Tag>
              ) : (
                <Tag color="gold">Not Synced</Tag>
              )} */}
            </div>
            <div>
              {/* {!data.isSynced && (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleSync(data.id)}
                >
                  Sync
                </Button>
              )} */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
