export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard",
    iconClassName: "bi bi-cast",
    route_key: "dashboard",
  },
  {
    name: "Users Management",
    exact: true,
    to: "/user-management",
    iconClassName: "bi bi-people",
    route_key: "user-management",
  },

  {
    name: "University Management",
    exact: true,
    to: "/university-management",
    iconClassName: "bi bi-bank2",
    route_key: "university-management",
  },

  {
    name: "Courses Management",
    exact: true,
    to: "/courses-management",
    iconClassName: "bi bi-book",
    route_key: "courses-management",
  },

  {
    name: "Students",
    exact: true,
    to: "/enquiries",
    iconClassName: "bi bi-clipboard",
    route_key: "enquiries",
  },

  {
    name: "Applications Management",
    exact: true,
    to: "/applications-management",
    iconClassName: "bi bi-hdd-stack",
    route_key: "applications-management",
  },

  {
    name: "Workflows",
    exact: true,
    to: "/workflows",
    iconClassName: "bi bi-app-indicator",
    route_key: "workflows",
  },
  {
    name: "Scholarship",
    exact: true,
    to: "/scholarship",
    iconClassName: "bi bi-gift",
    route_key: "scholarship",
  },
  {
    name: "Reports Management",
    exact: true,
    to: "/reports",
    iconClassName: "bi bi-printer",
    route_key: "reports",
  },
  {
    name: "Event Management",
    exact: true,
    to: "/event-management",
    iconClassName: "bi bi-calendar2-event",
    route_key: "events",
  },
  {
    name: "PopUp Message",
    exact: true,
    to: "/popup-message",
    iconClassName: "bi bi-aspect-ratio",
    route_key: "popup-message",
  },
  {
    name: "Career Management",
    exact: true,
    to: "/career-management",
    iconClassName: "bi bi-journals",
    route_key: "career-management",
  },
  {
    name: "Blog Management",
    exact: true,
    to: "/blog-management",
    iconClassName: "bi bi-chat-text",
    route_key: "blog-management",
  },
  {
    name: "Settings",
    exact: true,
    to: "/settings",
    iconClassName: "bi bi-gear",
    route_key: "settings",
  },
];
