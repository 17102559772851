import { Button, message, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useParams } from "react-router-dom";
import { PostDataAuth } from "../../../apiService/PostData";
import useCourseLevels from "../../../hooks/useCourseLevels";
import useIntakeMonths from "../../../hooks/useIntakeMonths";
import useSubjects from "../../../hooks/useSubjects";
import { apis } from "../../../properties";
import { OpenAI } from "openai";

const { Option } = Select;

const CourseListView = () => {
  const params = useParams();
  const [countryId, setCountryId] = useState({});
  const [uniObj, setUniObj] = useState({});
  const [courseList, setCourseList] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [selectedCourseId, setSelectedCourseId] = useState(null); // State to track selected course
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedCourseLevel, setSelectedCourseLevel] = useState(null);
  const [selectedIntake, setSelectedIntake] = useState(null);
  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });
  const courseLevels = useCourseLevels(filterDataOther);
  const subjects = useSubjects(filterDataOther);
  const intakeMonths = useIntakeMonths(filterDataOther);

  useEffect(() => {
    const getScrapData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/get-scarp-json/single-uni/${params.id}?country=${params.pageKey}`
      );
      const data = await response.json();
      setUniObj(data?.uniObj);
      data?.uniObj?.subjects.forEach((subject) => {
        subject.courses.forEach((course) => {
          setCourseList((prev) => [...prev, course]);
        });
      });
      switch (params.pageKey) {
        case "AU":
          setCountryId(13);
          break;
        case "CA":
          setCountryId(38);
          break;
        case "NZ":
          setCountryId(157);
          break;
        case "UK":
          setCountryId(230);
          break;
        default:
          setCountryId(230);
      }
    };
    getScrapData();
  }, [params.id, params.pageKey]);

  const handleCourseClick = (courseId) => {
    setSelectedCourseId((prevId) => (prevId === courseId ? null : courseId));
  };

  const handleOverviewChange = (value) => {
    setSelectedData((prevData) => ({
      ...prevData,
      overview: value,
    }));
  };

  const handleDetailChange = (field, value) => {
    setSelectedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleEntryRequirementsChange = (value) => {
    setSelectedData((prevData) => ({
      ...prevData,
      entryRequirements: value,
    }));
  };

  const handleAICheck = async (contentText, type) => {
    try {
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const response = await openai.chat.completions.create({
        messages: [
          {
            role: "user",
            content: `Rewrite the following text: ${contentText}`,
          },
        ],
        model: "gpt-3.5-turbo",
      });

      console.log(response.choices[0].message.content);
      setSelectedData((prevData) => ({
        ...prevData,
        [type]: response.choices[0].message.content,
      }));
      message.success("AI check completed successfully");
    } catch (error) {
      console.log("Error in AI check: ", error);
      message.error("Error in AI check");
    }
  };

  const handleSaveAndSync = async (id) => {
    const postData = courseList.find((item) => item.id === id);
    postData.name = uniObj.name;
    postData.location = uniObj.location;
    postData.subjects = selectedSubject;
    postData.courseLevel = selectedCourseLevel;
    postData.isChecked = true;
    postData.country_id = countryId;

    const intakes = [
      {
        month_id: selectedIntake,
        year_id: 4,
        course_limit: 30,
      },
    ];

    postData.intakes = intakes;
    Progress.show();
    PostDataAuth(apis.SYNC_SCRAP_DATA, postData).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        Progress.hide();
        message.success(responseJson.message);
      } else if (responseJson.status === "error") {
        Progress.hide();
        message.error(responseJson.message);
      }
    });
  };

  const handleUpdateJson = async () => {
    const postData = {
      ...selectedData,
      uniId: params.id,
      country: params.pageKey,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/get-scarp-json/update-uni-object`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    );
    const data = await response.json();
    message.success(data.message);
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <p className="common_header">
          {uniObj.name} | <span>{uniObj.location}</span>
        </p>
        {courseList.map((course) => (
          <div
            key={course.id}
            style={{
              marginBottom: 10,
            }}
          >
            <div
              className="data_row"
              style={{
                background: selectedCourseId === course.id ? "#f0f0f0" : "",
                cursor: "pointer",
              }}
              onClick={() => {
                handleCourseClick(course.id);
                setSelectedData(course);
              }}
            >
              <div>{course.title}</div>
              {course.isChecked ? (
                <Tag color="green">Checked</Tag>
              ) : (
                <Tag color="gold">Need to Check</Tag>
              )}
            </div>
            {selectedCourseId === course.id && (
              <div className="course_details">
                <div
                  className="cd_row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="cd_row">
                    <span>Overview: </span>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() =>
                        handleAICheck(selectedData.overview, "overview")
                      }
                    >
                      AI Check
                    </Button>
                  </div>
                  <textarea
                    value={selectedData.overview}
                    rows={5}
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      border: "1px solid #ccc",
                      padding: 5,
                    }}
                    onChange={(e) => handleOverviewChange(e.target.value)}
                  />
                </div>

                <div className="cd_row">
                  <span>Type And Duration: </span>
                  <input
                    type="text"
                    value={selectedData.typeAndDuration}
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      border: "1px solid #ccc",
                      padding: 5,
                    }}
                    onChange={(e) =>
                      handleDetailChange("typeAndDuration", e.target.value)
                    }
                  />
                </div>
                <div className="cd_row">
                  <span>Start Date: </span>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      border: "1px solid #ccc",
                      padding: 5,
                    }}
                    value={selectedData.startDate}
                    onChange={(e) =>
                      handleDetailChange("startDate", e.target.value)
                    }
                  />
                </div>
                <div className="cd_row">
                  <span>Price: </span>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      border: "1px solid #ccc",
                      padding: 5,
                    }}
                    value={selectedData.price}
                    onChange={(e) =>
                      handleDetailChange("price", e.target.value)
                    }
                  />
                </div>
                <div
                  className="cd_row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="cd_row">
                    <span>Entry Requirements: </span>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() =>
                        handleAICheck(
                          selectedData.entryRequirements,
                          "entryRequirements"
                        )
                      }
                    >
                      AI Check
                    </Button>
                  </div>
                  <textarea
                    value={selectedData.entryRequirements}
                    rows={5}
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      border: "1px solid #ccc",
                      padding: 5,
                    }}
                    onChange={(e) =>
                      handleEntryRequirementsChange(e.target.value)
                    }
                  />
                </div>
                <div
                  className="cd_row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Course Level</label>
                  <Select
                    size="large"
                    placeholder="Select Course Level"
                    allowClear
                    onChange={(value) => setSelectedCourseLevel(value)}
                  >
                    {courseLevels.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.level}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div
                  className="cd_row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Subject</label>
                  <Select
                    size="large"
                    placeholder="Select Subject"
                    mode="multiple"
                    allowClear
                    onChange={(value) => setSelectedSubject(value)}
                  >
                    {subjects.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div
                  className="cd_row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Intake</label>
                  <Select
                    size="large"
                    placeholder="Select Intake"
                    allowClear
                    onChange={(value) => setSelectedIntake(value)}
                  >
                    {intakeMonths.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.months}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="srap_btn_row">
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => handleUpdateJson()}
                  >
                    Update Json
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => handleSaveAndSync(selectedData.id)}
                  >
                    Save & Sync
                  </Button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseListView;
