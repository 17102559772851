import React, { useEffect } from "react";
import logo from "../../assets/images/logo-s.png";
import MenuItem from "./MenuItem";
import { menuItems } from "./MenuItemRoutes";
import { useStore } from "../../store";

const SideMenu = () => {
  const activeSideMenu = useStore((state) => state.activeSideMenu);
  const pagePrivileges = useStore((state) => state.pagePrivileges);
  // Get the window height
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  // Calculate the max height for the menu
  const menuMaxHeight = windowHeight - 20;

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  useEffect(() => {
    let menuItems = document.querySelectorAll(".menu-item");
    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  console.log("page_pri", pagePrivileges);
  return (
    <div className={`side-menu ${activeSideMenu ? "inactive" : ""}`}>
      <div className="top-section">
        <div className="logo">
          <img src={logo} alt="logo" />
          <span>Campus Direct</span>
        </div>
      </div>

      <div
        className="main-menu"
        style={{
          maxHeight: menuMaxHeight + "px",
        }}
      >
        <ul>
          {menuItems.map(
            (menuItem, index) =>
              pagePrivileges.includes(menuItem.route_key) && (
                <MenuItem
                  key={index}
                  name={menuItem.name}
                  exact={menuItem.exact}
                  to={menuItem.to}
                  subMenus={menuItem.subMenus || []}
                  iconClassName={menuItem.iconClassName}
                  route_key={menuItem.route_key}
                />
              )
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
